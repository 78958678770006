import React, { Component } from "react";
import { graphql } from "react-apollo";
import { getBookQuery } from "../../queries/bookqueries";
import amazonLogo from "../../img/amazon-logo_grey.png";

class BookDetails extends Component {
  splitGenres(book) {
    return book.genre.map((genre, index) => {
      return <li key={"genre" + index}>{genre}</li>;
    });
  }

  // showAmazonLink() {
  //   let amazonDisplay = document.getElementById("amazon");
  //   console.log(amazonDisplay.style.display);
  //   if (amazonDisplay.style.display !== "none") {
  //     amazonDisplay.style.display = "none";
  //   } else {
  //     amazonDisplay.style.display = "initial";
  //   }
  //   console.log(amazonDisplay.style.display);
  // }

  displayBookDetails() {
    const { book } = this.props.data;
    if (book) {
      let src = book.cover,
        maxHeight = "230px";
      if (!book.cover) {
        src = amazonLogo;
        maxHeight = "40px";
      }
      return (
        <div className="book-details-container">
          <h5>
            <a
              style={{ color: "white" }}
              href="{`https://www.amazon.com/gp/search?ie=UTF8&tag=toomanylists-20&linkCode=ur2&&index=books&keywords=${
                  book.name
                }`}"
            >
              {" "}
              {book.name} <i className="tiny material-icons">launch</i>
            </a>
          </h5>
          <div className="book-details">
            <div>
              <a
                id="amazon"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.amazon.com/gp/search?ie=UTF8&tag=toomanylists-20&linkCode=ur2&&index=books&keywords=${
                  book.name
                }`}
              >
                <img src={src} alt="Amazon Logo" style={{ maxHeight }} />
              </a>
            </div>
            <div className="book-details-text">
              <div className="book-details-header">Author:</div>
              <ul> {book.author.name}</ul>
              <div className="book-details-header">Genre(s):</div>
              <ul>{this.splitGenres(book)}</ul>
              <div className="book-details-header">
                Other books by this author:
              </div>
              <ul className="other-books">
                {book.author.books
                  .filter(item => item.id !== book.id)
                  .map(item => {
                    return <li key={item.id}>{item.name}</li>;
                  })}
              </ul>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>No book selected.</div>;
    }
  }

  render() {
    return <div id="book-details">{this.displayBookDetails()}</div>;
  }
}

export default graphql(getBookQuery, {
  options: props => {
    return {
      variables: {
        id: props.bookId
      }
    };
  }
})(BookDetails);
